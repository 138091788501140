











import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import { Columns } from 'vue-columns';
import UserCard from './UserCard.vue';
import { IUser } from '@/models/user';
import TeamsModule from '@/store/modules/teams/TeamsModule';
import SpecializationsModule from '@/store/modules/specializations/SpecializationsModule';
import { Sort, Specialisations } from '@/typings/domain';

@Component({
  name: 'TeamGrid',
  components: {
    Columns,
    UserCard,
  },
})
export default class TeamGrid extends Vue {
  @Prop() selectedSpecializations: any;
  @Prop() selectedSort: any;
  columnsCount = 5;
  public applySort = '';
  public applyFilter = '';
  public page = 2;
  
  async mounted() {
    
    TeamsModule.clearTeams();
    const intersectionObserver = new IntersectionObserver(entries => {
      if (entries[0].intersectionRatio <= 0) return;
        this.loadItems();
    });
    // start observing
    intersectionObserver.observe(document.querySelector('.team-grid__end'));
  }

  async loadItems()
  { 
    const payload = 
    {
      'filter[specialisations]': this.applyFilter,
      'sort[by]': this.applySort === 'sort[by]=rating' ? 'rating' : 'created_at',
      'sort[direction]': 'desc',
    }


    await TeamsModule.fetchTeams(
      {
        page: this.page, 
        ...payload,
      })
    this.page++;
  }

  @Watch('selectedSpecializations')
  onSelectedSpecChanged(val:Specialisations[])
  { 
    this.page = 1;
    if(!val.length)
    { 
      
      if(this.selectedSort.length)
      { 
        
        if(this.selectedSort[0].sortType === 'rating')
        {
          this.applySort = 'sort[by]=rating'
          TeamsModule.fetchTeams({page: this.page, 'sort[by]': 'rating',  'sort[direction]': 'desc', 'filter[specialisations]': this.applyFilter})
        }
        else if(this.selectedSort[0].sortType === 'created_at')
        {
          this.applySort = 'sort[by]=created_at'
          TeamsModule.fetchTeams({page: this.page,'sort[by]': 'created_at', 'sort[direction]': 'desc', 'filter[specialisations]': this.applyFilter})
        }
      }
      else
      {
        this.applyFilter = '';
        TeamsModule.fetchTeams({page: this.page})
      }
    }
    else
    {
      TeamsModule.clearTeams();
      if(this.selectedSort.length)
      { 
        
        if(this.selectedSort[0].sortType === 'rating')
        {
          this.applySort = 'sort[by]=rating'
          TeamsModule.fetchTeams({page: this.page, 'sort[by]': 'rating',  'sort[direction]': 'desc', 'filter[specialisations]': val[0].id})
        }
        else if(this.selectedSort[0].sortType === 'created_at')
        {
          this.applySort = 'sort[by]=created_at'
          TeamsModule.fetchTeams({page: this.page,'sort[by]': 'created_at', 'sort[direction]': 'desc', 'filter[specialisations]': val[0].id})
        }
      }
      else
      {
        this.applyFilter = `${val[0].id}`
        TeamsModule.fetchTeams({page:this.page, 'filter[specialisations]': val[0].id})
      }
    }
    this.page++;
  }
  
  @Watch('selectedSort')
  onSelectedSort(val: Sort[])

  {
    this.page = 1;
    if(val.length)
    { 
      TeamsModule.clearTeams();
      
      if(val[0].sortType === 'rating')
      {
        this.applySort = 'sort[by]=rating'
        TeamsModule.fetchTeams({page: this.page, 'sort[by]': 'rating',  'sort[direction]': 'desc', 'filter[specialisations]': this.selectedSpecializations.length ? this.selectedSpecializations[0].id: null})
      }
      else if(val[0].sortType === 'created_at')
      {
        this.applySort = 'sort[by]=created_at'
        TeamsModule.fetchTeams({page: this.page,'sort[by]': 'created_at', 'sort[direction]': 'desc', 'filter[specialisations]': this.selectedSpecializations.length ? this.selectedSpecializations[0].id: null})
      }
    }
    else
    { 
      if(this.selectedSpecializations.length)
      { 
        TeamsModule.fetchTeams({page:this.page, 'filter[specialisations]': this.selectedSpecializations[0].id})
      }
      else
      {
        this.applySort = '';
        TeamsModule.fetchTeams({page: this.page})
      }
    }
    this.page++;
  }
  get teams() 
  {
    return TeamsModule.teams
  }
}
