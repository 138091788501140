









import { Vue, Component, Prop, Emit } from 'vue-property-decorator';
import Icon from '@/components/ui/icon/Icon.vue';

@Component({
    components: { Icon },
})
export default class ToggleSwitch extends Vue {
    @Prop({ default: 'regular' }) size: string;
    @Prop() value: boolean;

    @Emit('input')
    change() {
        return !this.value;
    }
}
