




















import { Vue, Component, Watch } from 'vue-property-decorator';
import ContextMenu from '@/components/context-menu/ContextMenu.vue';
import GeneralModule from '@/store/modules/general/GeneralModule';

@Component({
  components: {
    ContextMenu,
  },
})
export default class FiltersMobile extends Vue {
  private _uid: number;
  public isVisible = false;

  get id(): string {
    return this._uid.toString();
  }

  toggle() {
    this.isVisible = !this.isVisible;
  }

  public close() {
    this.isVisible = false;
  }

  @Watch('isVisible')
  onVisabilityChange() {
    if (this.isVisible) {
      GeneralModule.addScrollBlocker(this.id);
    } else {
      GeneralModule.removeScrollBlocker(this.id);
    }
  }

  beforeUnmount() {
    GeneralModule.removeScrollBlocker(this.id);
  }
}
